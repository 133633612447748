<template>
  <div class="finance">
    <div class="box1"></div>
    <div class="box2">
      <!-- <h2>大会亮点</h2>
      <p>Conference highlights</p> -->
      <!-- <img class="b2" src="../assets/img01/box2.png" alt="" />
      <div class="img">
        <img src="../assets/img01/margin.png" alt="" />
      </div> -->
    </div>
    <div class="box3">
      <!-- <h2>嘉宾介绍</h2>
      <p>Guest introduction</p> -->
      <img class="b3" src="../assets/img01/box3.png" alt="" />
      <div class="swiper_banner">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(item, index) in 3"
              :key="index"
              :style="`background:url(${img[index]}) 0 0/cover no-repeat`"
            >
              <div class="top">
                <img src="../assets/img01/cai.png" alt="" />
              </div>
              <div class="bottom">
                <div class="txt">
                  <p>蒋建圣</p>
                  <p>执行董事</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 如果需要导航按钮 -->
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
      </div>
    </div>
    <div class="box4">
      <!-- <h2>议程介绍</h2>
      <p>Agenda introduction</p> -->
      <img class="b4" src="../assets/img01/box4.png" alt="" />
      <div class="box4_swiper">
        <div class="swiper-containers" ref="swiper">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item, index) in 6" :key="index">
              <div class="sheet">
                <div class="head">
                  <div>会议议题</div>
                  <div>嘉宾</div>
                  <div>嘉宾简介</div>
                </div>
                <div class="content">
                  <div>
                    <p>金融数智化未来</p>
                    <p>PM 13:45-14:10</p>
                  </div>
                  <div>刘曙峰</div>
                  <div>恒生电子总裁</div>
                </div>

                <div class="content">
                  <div>
                    <p>金融数智化未来</p>
                    <p>PM 13:45-14:10</p>
                  </div>
                  <div>刘曙峰</div>
                  <div>恒生电子总裁</div>
                </div>

                <div class="content">
                  <div>
                    <p>金融数智化未来</p>
                    <p>PM 13:45-14:10</p>
                  </div>
                  <div>刘曙峰</div>
                  <div>恒生电子总裁</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-button-prevs" ref="prevs"></div>
        <div class="swiper-button-nexts" ref="nexts"></div>
      </div>
    </div>
    <div class="box5">
      <!-- <h2>产品与解决方案</h2>
      <p>Products And Solutions</p> -->
      <img class="b5" src="../assets/img01/box5.png" alt="" />
      <div class="sam_box">
        <div
          class="list"
          v-for="(item, index) in 4"
          :key="index"
          :style="`background:url(${bg[index]}) 0 0/cover no-repeat`"
        >
          <p class="txt1">洞见投研</p>
          <p class="txt2">
            数智中国正全力推进，金融行业数字化转型深入发展数智中国正全力推进，金融行业数字化转型深入发展
            数智中国正全力推进，金融行业数字化转型深入发展
            数智中国正全力推进，金融行业数字化转型深入发展
            数智中国正全力推进，金融行业数字化转型深入发展
            数智中国正全力推进金融行业数字化转型深入发展
            数智中国正全力推进，金融行业数字化转型深入发展
          </p>
        </div>
      </div>
    </div>
    <div class="box6">
      <!-- <h2>合作伙伴</h2>
      <p>Cooperative Partner</p> -->
      <img class="b6" src="../assets/img01/box6.png" alt="" />
      <div class="numimg">
        <div class="image"></div>
        <div class="image"></div>
        <div class="image"></div>
        <div class="image"></div>
        <div class="image"></div>
        <div class="image"></div>
        <div class="image"></div>
        <div class="image"></div>
        <div class="image"></div>
        <div class="image"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
export default {
  data() {
    return {
      img: [
        require("@/assets/img01/b1.png"),
        require("@/assets/img01/b2.png"),
        require("@/assets/img01/b3.png"),
      ],
      bg: [
        require("@/assets/img01/sa1.png"),
        require("@/assets/img01/sa2.png"),
        require("@/assets/img01/sa3.png"),
        require("@/assets/img01/sa4.png"),
      ],
      swiper: null,
      swipers: null,
    };
  },
  mounted() {
    this.getswiper();
    this.getswipers();
  },
  methods: {
    getswiper() {
      this.swiper = new Swiper(".swiper-container", {
        loop: true,
        slidesPerView: 3,
        spaceBetween: 60,
        // 如果需要前进后退按钮
        nextButton: ".swiper-button-next",
        prevButton: ".swiper-button-prev",
      });
    },
    getswipers() {
      this.swipers = new Swiper(this.$refs.swiper, {
        loop: true,
        // 如果需要前进后退按钮
        nextButton: this.$refs.nexts,
        prevButton: this.$refs.prevs,
      });
    },
  },
};
</script>

<style scoped>
.finance {
  margin-top: 72px;
}
.box1 {
  width: 100%;
  height: 541px;
  background: url(../assets/img01/banner_new.png) 0 0 / cover no-repeat;
}
.box2 {
  width: 100%;
  height: 673px;
  /* background: url(../assets/img01/box2_bg.png) 0 0 / cover no-repeat; */
  text-align: center;
}
.box2 .b2 {
  width: 286px;
  height: 77px;
  margin: 100px auto 50px;
}
.box2 h2 {
  font-size: 36px;
  font-weight: 400;
  color: #212223;
  line-height: 50px;
  margin-top: 100px;
}
.box2 p {
  font-size: 16px;
  font-weight: 100;
  color: #212223;
  line-height: 22px;
  margin-top: 5px;
  margin-bottom: 50px;
}
.box2 .img img {
  width: 339px;
  height: 339px;
  margin: 0 auto;
}
.box3 {
  width: 100%;
  height: 668px;
  background: url(../assets/img01/banner_new3.png) 0 0 / cover no-repeat;
  text-align: center;
  padding-top: 96px;
}
.box3 .b3 {
  width: 286px;
  height: 77px;
  margin: 0 auto 50px;
}
.box3 h2 {
  font-size: 36px;
  font-weight: 400;
  color: #ffffff;
  line-height: 50px;
}
.box3 > p {
  font-size: 16px;
  font-weight: 100;
  color: #ffffff;
  line-height: 22px;
  margin-top: 5px;
  margin-bottom: 50px;
}
.box3 .swiper-container {
  width: 963px;
  height: 354px;
  margin: 0 auto;
}
.box3 .swiper-container .top {
  height: 235px;
}
.box3 .swiper-container .bottom {
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.box3 .swiper-container .bottom .txt {
  width: fit-content;
  height: 56px;
}
.box3 .bottom .txt p:nth-child(1) {
  font-size: 22px;
  color: #ffffff;
}
.box3 .bottom .txt p:nth-child(2) {
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
}
.box3 .swiper_banner {
  width: 963px;
  height: 354px;
  margin: 0 auto;
  position: relative;
}
.box3 .top {
  display: flex;
  align-items: center;
  justify-content: center;
}
.box3 .top img {
  width: 160px;
  height: 160px;
}
.box3 .swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  width: 64px;
  height: 63px;
  background: url(../assets/img01/left_l.png) 0 0 / cover no-repeat;
  left: -117px;
  z-index: 1;
}
.box3 .swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  width: 64px;
  height: 63px;
  background: url(../assets/img01/right_r.png) 0 0 / cover no-repeat;
  right: -117px;
  z-index: 1;
}
.box4 {
  text-align: center;
  min-height: 1000px;
  background: url(../assets/img01/bg123.png) 0 0 / cover no-repeat;
}

.box4 .b4 {
  width: 286px;
  height: 77px;
  margin: 100px auto 0;
}
.box4 h2 {
  font-size: 36px;
  font-weight: 400;
  color: #212223;
  line-height: 50px;
  margin-top: 100px;
}
.box4 p {
  font-size: 16px;
  font-weight: 100;
  color: #212223;
  line-height: 22px;
}
.box4 .sheet {
  width: 912px;
  margin: 50px auto 0;
}
.box4 .sheet .head {
  padding: 0 50px;
  height: 108px;
  display: flex;
  background: url(../assets/img01/head.png) 0 0 / cover no-repeat;
  align-items: center;
  text-align: left;
}
.box4 .sheet .head > div {
  font-size: 20px;
  font-weight: 500;
  color: #ffffff;
  line-height: 28px;
}
.box4 .sheet .head > div:nth-child(1) {
  flex: 5;
}
.box4 .sheet .head > div:nth-child(2) {
  flex: 4;
}
.box4 .sheet .head > div:nth-child(3) {
  flex: 1;
}
.box4 .sheet .content {
  height: 90px;
  display: flex;
  border-bottom: 1px solid #d2dae3;
  text-align: left;
  padding: 0 27px;
  align-items: center;
}
.box4 .sheet .content div {
  font-size: 18px;
  font-weight: 400;
  color: #212223;
  line-height: 25px;
}
.box4 .sheet .content > div:nth-child(1) {
  flex: 5;
}
.box4 .sheet .content > div:nth-child(1) > p:nth-child(1) {
  font-size: 18px;
  color: #212223;
  line-height: 25px;
}
.box4 .sheet .content > div:nth-child(1) > p:nth-child(2) {
  font-size: 14px;
  font-weight: 400;
  color: #212223;
  line-height: 20px;
}
.box4 .sheet .content > div:nth-child(2) {
  flex: 3.6;
}

.box4 .sheet .content > div:nth-child(3) {
  flex: 1.4;
}
.box4 >>> .swiper-containers {
  width: 912px;
  min-height: 800px;
  overflow: hidden;
  margin: 0 auto;
  position: relative;
}
.box4 .swiper-button-prevs {
  width: 64px;
  height: 63px;
  position: absolute;
  left: -150px;
  background: url(../assets/img01/left_l.png) 0 0 / cover no-repeat;
  z-index: 1;
  top: 50%;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 1px 1px 10px #ccc;
}
.box4 .swiper-button-nexts {
  width: 64px;
  height: 63px;
  position: absolute;
  background: url(../assets/img01/right_r.png) 0 0 / cover no-repeat;
  right: -150px;
  top: 50%;
  z-index: 1;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 1px 1px 10px #ccc;
}
.box4 .box4_swiper {
  width: 912px;
  margin: 0 auto;
  position: relative;
}

.box5 {
  text-align: center;
}
.box5 .b5 {
  width: 394px;
  height: 77px;
  margin: 100px auto 0;
}
.box5 h2 {
  margin-top: 100px;
  font-size: 36px;
  font-weight: 400;
  color: #212223;
  line-height: 50px;
}
.box5 p {
  font-size: 16px;
  font-weight: 100;
  color: #212223;
  line-height: 22px;
}
.box5 .sam_box {
  width: 1200px;
  height: 500px;
  margin: 50px auto 100px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.box5 .sam_box .list {
  width: 570px;
  height: 231px;
  padding: 32px;
}
.box5 .sam_box .list .txt1 {
  font-size: 26px;
  font-weight: 600;
  color: #ffffff;
  line-height: 37px;
  width: fit-content;
}
.box5 .sam_box .list .txt2 {
  margin-top: 15px;
  width: 498px;
  height: 105px;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  line-height: 21px;
  background: linear-gradient(
    243deg,
    #535fd5 0%,
    #ea406b 41%,
    #ff7b52 65%,
    #efea91 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: initial;
}

.box6 {
  height: 503px;
  text-align: center;
}
.box6 h2 {
  font-size: 36px;
  font-weight: 400;
  color: #212223;
  margin-top: 100px;
}
.box6 p {
  font-size: 16px;
  font-weight: 100;
  color: #212223;
}
.box6 .numimg {
  width: 1200px;
  height: 176px;
  margin: 50px auto 100px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.box6 .numimg .image {
  width: 206px;
  height: 70px;
  border-radius: 10px;
  background: pink;
}
.box6 .b6 {
  width: 286px;
  height: 77px;
  margin: 0 auto;
}
</style>